
import request from './request';


export default new function API() {

  this.createorder = params => request.post({action:"createorder", ...params});
  this.myorder = params => request.post({action:"myorder", ...params});

  this.pay = params => request.post({action:"pay", ...params});
  this.onlinepay = params => request.post({action:"onlinepay", ...params});

  this.paystatus = params => request.post({action:"paystatus", ...params});

  this.orderinfo = params => request.post({action:"orderinfo", ...params});

  this.orderrefund = params => request.post({action:"orderrefund", ...params});

  this.orderrefundexpress = params => request.post({action:"orderrefundexpress", ...params});

  this.createvip = params => request.post({action:"createvip", ...params});

}();
