<template>
  <div  class="ma-md-5 mb-16 px-0 " >


    <div class="d-flex">
      <v-btn icon small class="px-4 mt-6" to="/profile/menu" v-if="$vuetify.breakpoint.mobile">
        <v-icon size=20>mdi-arrow-left</v-icon>
      </v-btn>

      <div class="text-h6 font-weight-bold my-6">订单中心</div>
    </div>

    <div tile v-for="order in orderlist" :key="order.order_id">
      <v-card class="d-flex item-header pa-4 justify-space-between" flat tile>
        <div class="">订单编号:  {{order.order_no}}</div>
        <div class="" v-if="!$vuetify.breakpoint.mobile">下单时间:  {{order.create_time_str}}</div>
      </v-card>
      <v-card class="my-4 " till flat>
      <v-row >
        <v-col class="pl-md-8" cols="6">
          <v-row v-for="item in order.order_item_list" :key="item.sku_id">
            <v-col class="" cols="3">
              <v-img :src="domain + item.picture.pic_cover_small" max-height="200" max-width="200"></v-img>
            </v-col>
            <v-col class="d-flex flex-column justify-center" cols="9" >
              <div class="text-h7 font-weight-bold ">{{item.goods_name}}</div>
              <div class="text-h7  ">{{item.sku_name}} </div>
              <div class="text-h7  ">{{item.price}}  x{{item.num}}</div>
              
            </v-col>
          </v-row>
        </v-col>
        <v-col class="d-flex flex-column justify-center" v-if="!$vuetify.breakpoint.mobile" cols="2">
          {{order.pay_money}}
        </v-col>
         <v-col class="d-flex flex-column " cols="2">
          {{order.status_name}}
        </v-col>

        <v-col class="d-flex flex-column " cols="4"  md="2">

          <v-btn  dark outlined v-if="order.pay_status==0 && order.order_status==0" tile class="main-btn" @click="$router.push('/profile/orderdetail?paying=1&out_trade_no='+order.out_trade_no)">立即支付</v-btn>
          <v-btn  dark outlined  tile class="main-btn" @click="$router.push('/profile/orderdetail?out_trade_no='+order.out_trade_no)">查看</v-btn>
        </v-col>
      </v-row>
      </v-card>
       <v-card class="d-flex justify-space-between my-4 " v-for="express in order.express_detail" :key="express.id" till flat>
          <div class="">收货地址:  {{order.receiver_address}}</div>
          <div class="">物流单号:  {{express.express_name}} , {{express.express_no}}</div>

       </v-card>
    </div>

    <div v-if="orderlist.length==0" class="text-center text-h5 grey--text">订单空空如也~</div>

    <div  class="pa-5 py-md-12 text-center" v-if="showmore">
      <v-btn  dark outlined width="30%" tile class="main-btn" @click="load">加载更多</v-btn>
      <div><v-icon>mdi-chevron-down</v-icon></div>
    </div>

  </div>
</template>

<script>

import orderApi from '@/api/order'
import { mapState } from 'vuex'



export default {


  data: () => ({
    page:1,
    orderlist : [],
    showmore: false

  
    //
  }),
  computed: {
    ...mapState({
      domain: state => state.app.imgdomain,
    }),
  },
  created() {
    this.orderlist = []
    this.load()
    
  },
  methods: {
    homesearch() {
      alert(3)
    },

    load(){
      let params = {page:this.page}
      orderApi.myorder(params).then(res => {
        
        //this.orderlist = res.data.data
        for (let i=0; i<res.data.data.length; i++) {
          this.orderlist.push(res.data.data[i])
        }
        this.showmore = res.data.page_count>this.page
        this.page++
        
      })
    }
    
  },
};
</script>
<style scoped>

.item-header {
  background-color: black;
  color:#ffffff;
  
  width:100%;
}


</style>

